/* Universal javascript */
import '/src/css/app.css';
import Alpine from 'alpinejs'

// Get any third-party libraries
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

// Import Plyr
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import refreshCsrfToken from './utilities/refreshCsrfToken.js';

const lazySizesConfig = lazySizes.cfg;
lazySizesConfig.init = false;
lazySizes.init();

window.Alpine = Alpine;
window.Plyr = Plyr;
Alpine.start()

/* CRAFT_CSRF_TOKEN refresh */
refreshCsrfToken();

