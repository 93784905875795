// csrfUpdater.js
export default function refreshCsrfToken() {
    // Find all forms in the document
    const forms = document.querySelectorAll('form');

    // Iterate through each form
    forms.forEach(form => {
      // Check if the form contains an input with name="CRAFT_CSRF_TOKEN"
      const csrfInput = form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');

      if (csrfInput) {
        // If found, fetch the new CSRF token
        fetch('/actions/blitz/csrf/token')
          .then(response => response.text())
          .then(result => {
            // Update the CSRF token input value
            csrfInput.value = result;
          })
          .catch(error => {
            console.error('Error fetching CSRF token:', error);
          });
      }
    });
}
